// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".password_xBQK1 {\n  color: #868991;\n  font-size: 20px;\n  letter-spacing: 2px;\n}\n.bold_nDGF7 {\n  font-weight: 600;\n}\n.cardFieldWrapper_m6ZMx {\n  border-top: 1px solid transparent;\n  border-bottom: 1px solid transparent;\n}\n.rowWrapper__4gof {\n  width: 100%;\n}\n.mainWrapper__7YSA {\n  width: 100%;\n}\n.password_xBQK1 {\n  color: #868991;\n  font-size: 20px;\n  letter-spacing: 2px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ClientCard/MyProfile/components/ProfileTab/AuthInfo/style.m.less"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,eAAA;EACA,mBAAA;AADF;AAIA;EACE,gBAAA;AAFF;AAKA;EACE,iCAAA;EACA,oCAAA;AAHF;AAMA;EACE,WAAA;AAJF;AAOA;EACE,WAAA;AALF;AAQA;EACE,cAAA;EACA,eAAA;EACA,mBAAA;AANF","sourcesContent":["@import 'style/palette';\n\n.password {\n  color: @gray-7;\n  font-size: 20px;\n  letter-spacing: 2px;\n}\n\n.bold {\n  font-weight: 600;\n}\n\n.cardFieldWrapper {\n  border-top: 1px solid transparent;\n  border-bottom: 1px solid transparent;\n}\n\n.rowWrapper {\n  width: 100%;\n}\n\n.mainWrapper {\n  width: 100%;\n}\n\n.password {\n  color: @gray-7;\n  font-size: 20px;\n  letter-spacing: 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"password": "password_xBQK1",
	"bold": "bold_nDGF7",
	"cardFieldWrapper": "cardFieldWrapper_m6ZMx",
	"rowWrapper": "rowWrapper__4gof",
	"mainWrapper": "mainWrapper__7YSA"
};
export default ___CSS_LOADER_EXPORT___;
