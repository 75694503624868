import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { Box, CardField, EMPTY_FIELD_VALUE, Space } from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'

import styles from './style.m.less'

export const LoginBlock: FC = () => {
    const username = useSelector(authSelector.selectUserName)

    const ValueComponent = (
        <Space direction="vertical" className={styles.mainWrapper}>
            <span className={styles.bold}>{username ?? EMPTY_FIELD_VALUE}</span>
        </Space>
    )

    return (
        <Box margin={[0, -24, 0, -48]} padding={[0, 60, 0, 48]} className={styles.cardFieldWrapper}>
            <CardField title="Логин" value={ValueComponent} padding={16} />
        </Box>
    )
}
