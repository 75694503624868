import { frontUrl } from '@constants/frontUrl'
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    RoleFormCard,
    Space,
    Tag,
    getFullName,
} from '@dltru/dfa-ui'
import { getAnketaTagProps } from '@resources/anketaState'
import { getQuestionnaireUrlByProfileType } from '@utils/profile'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { profileSelector } from '@store/profiles/details/selectors'

export const QuestionnaireInfo: FC = () => {
    const userFullName = useSelector(profileSelector.userFullName)
    const profileAgent = useSelector(profileSelector.selectProfileAgent)
    const profile = useSelector(profileSelector.selectProfile)
    const types = useSelector(profileSelector.selectProfileTypes)

    if (!types?.length) {
        return null
    }

    const tagPropsAgent = profileAgent?.application_status
        ? getAnketaTagProps(profileAgent.application_status)
        : undefined
    const tagPropsClient = profile?.profile_status
        ? getAnketaTagProps(profile.profile_status)
        : undefined

    return (
        <Card>
            <CardHeader title="Анкета пользователя" />
            <CardContent>
                <Space>
                    <RoleFormCard
                        title="Анкета пользователя"
                        subtitle={userFullName ?? ''}
                        isCompleted
                        tag={
                            tagPropsClient ? (
                                <Tag color={tagPropsClient.color}>{tagPropsClient.title}</Tag>
                            ) : undefined
                        }
                        to={getQuestionnaireUrlByProfileType(types)}
                    />
                    {profileAgent && (
                        <RoleFormCard
                            title="Анкета представителя"
                            subtitle={getFullName(profileAgent)}
                            tag={
                                tagPropsAgent ? (
                                    <Tag color={tagPropsAgent.color}>{tagPropsAgent.title}</Tag>
                                ) : undefined
                            }
                            isCompleted
                            to={`/questionnaire/${frontUrl.questionnaireRepresentative}`}
                        />
                    )}
                </Space>
                <Box margin={[24, 12, 0, 12]}>
                    <Link to="/remove-from-users">
                        <strong>Подать заявление на исключение из реестра пользователей</strong>
                    </Link>
                </Box>
            </CardContent>
        </Card>
    )
}
