import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { IEmissionOrder, ISecondaryOrder, UnionOrderType } from '@dltru/dfa-models'
import { Box, Table } from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'
import { dropOrderList, loadAllOrdersByUser } from '@store/orders/list'
import { ordersSelector } from '@store/orders/list/selectors'

import { EmissionOrderDetailsModal, SecondaryOrderDetailsModal } from '@components/Modals/Orders'
import { SecondaryRevokeModal } from '@components/Modals/Orders/Revoke'

import { FilterByStatus } from './FilterByStatus'
import { getGridConfig } from './gridConfig'

export const OrdersTab: FC = () => {
    const reduxDispatch = useDispatch()
    const navigate = useNavigate()
    const userId = useSelector(authSelector.selectUserUid)
    const isLoading = useSelector(ordersSelector.selectIsLoading)
    const data = useSelector(ordersSelector.selectData)
    const [isEmissionOrderModalVisible, setIsEmissionOrderModalVisible] = useState(false)
    const [isSecondaryOrderModalVisible, setIsSecondaryOrderModalVisible] = useState(false)
    const [isRevokeModalVisible, setIsRevokeModalVisible] = useState(false)
    const [currentOrder, setCurrentOrder] = useState<UnionOrderType>()

    useEffect(() => {
        if (userId) {
            reduxDispatch(loadAllOrdersByUser(userId))
        }
        return () => {
            reduxDispatch(dropOrderList())
        }
    }, [userId])

    const actionSecondaryCancel = (order: UnionOrderType) => {
        setCurrentOrder(order)
        setIsRevokeModalVisible(true)
    }

    const gridConfig = getGridConfig({ actionSecondaryCancel })

    const onRow = (order: UnionOrderType) => ({
        onClick: () => {
            switch (order.order_type) {
                case 'sell':
                case 'buy': {
                    setCurrentOrder(order)
                    setIsSecondaryOrderModalVisible(true)
                    setIsEmissionOrderModalVisible(false)
                    break
                }
                case 'early_repayment':
                    navigate(`/early-repayment/${order.id}`)
                    break
                default: {
                    setCurrentOrder(order)
                    setIsSecondaryOrderModalVisible(false)
                    setIsEmissionOrderModalVisible(true)
                }
            }
        },
    })

    return (
        <Box padding={[0, 24, 24, 24]} className="tablebox">
            <Table
                isLoading={isLoading}
                columns={gridConfig}
                dataSource={data as UnionOrderType[]}
                onRow={onRow}
                clickableRow
                headerRight={<FilterByStatus />}
                showSorterTooltip={false}
            />
            <SecondaryOrderDetailsModal
                isModalVisible={isSecondaryOrderModalVisible}
                setIsModalVisible={setIsSecondaryOrderModalVisible}
                order={currentOrder as ISecondaryOrder}
                onlyDetails
            />
            <EmissionOrderDetailsModal
                isModalVisible={isEmissionOrderModalVisible}
                setIsModalVisible={setIsEmissionOrderModalVisible}
                order={currentOrder as IEmissionOrder}
            />
            <SecondaryRevokeModal
                setIsModalVisible={setIsRevokeModalVisible}
                isModalVisible={isRevokeModalVisible}
                order={currentOrder as ISecondaryOrder}
            />
        </Box>
    )
}
