import React, { FC, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { AxiosError } from 'axios'

import { ResponseError } from '@dltru/dfa-models'
import { Button, ButtonProps, Upload } from '@dltru/dfa-ui'

import { profileSelector } from '@store/profiles/details/selectors'

import api from '@services/api'

import style from './UploadCertificate.m.less'

type UploadCertificateProps = Omit<ButtonProps, 'onError'> & {
    onAddCertificate: () => void
    onError: (code?: string) => void
    userUuid?: string
}

export const UploadCertificate: FC<UploadCertificateProps> = ({
    onAddCertificate,
    onError,
    userUuid,
    ...props
}) => {
    const profileWallet = useSelector(profileSelector.selectWalletDfaData)

    const addCertificateHandler = useCallback(async ({ file }) => {
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        reader.onload = async function () {
            try {
                if (!reader.result) {
                    throw new Error('no data')
                }
                const { error } = await api.lib.storeOctetStreamFileService(reader.result, userUuid)
                if (error) {
                    throw error
                } else {
                    onAddCertificate()
                }
            } catch (error) {
                onError((error as AxiosError<ResponseError>)?.response?.data?.error?.code)
            }
        }
        reader.onerror = function () {
            onError()
        }
    }, [])

    return (
        <Upload
            beforeUpload={() => false}
            onChange={addCertificateHandler}
            maxCount={1}
            fileList={[]}
            accept=".cer"
            disabled={!profileWallet}
        >
            <Button
                className={style.uploadCertificate}
                borderRadius={12}
                {...props}
                disabled={!profileWallet}
                tooltipOnDisable="Операция временно недоступна. Повторите попытку позже"
            >
                Добавить сертификат
            </Button>
        </Upload>
    )
}
