import { Card, CardContent, CardHeader, Divider } from '@dltru/dfa-ui'
import { FC } from 'react'

import { LoginBlock } from '@pages/ClientCard/MyProfile/components/ProfileTab/AuthInfo/LoginBlock'

import { EmailBlock } from './EmailBlock'
import { PasswordBlock } from './PasswordBlock'
import { PhoneBlock } from './PhoneBlock'

export const AuthInfo: FC = () => {
    return (
        <Card>
            <CardHeader title="Данные авторизации" />
            <CardContent>
                <LoginBlock />
                <PhoneBlock />
                <EmailBlock />
                <Divider margin={[8, 0, 24, 0]} />
                <PasswordBlock />
            </CardContent>
        </Card>
    )
}
