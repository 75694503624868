import { blockedRoles } from '@dltru/dfa-models'
import {
    Box,
    Card,
    Col,
    Divider,
    EMPTY_FIELD_VALUE,
    QualificationInfo,
    Row,
    VIEW_DATE_FORMAT,
} from '@dltru/dfa-ui'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'

import { profileSelector } from '@store/profiles/details/selectors'

import { QuestionnaireInfo } from '../../components/ProfileTab/QuestionnaireInfo'
import { CertificatesList } from '../../components/ProfileTab/СertificatesBlock'
import { CardVerticalField } from '../CardVerticalField'
import { AlertUserBlock } from './AlertUserBlock'
import { AuthInfo } from './AuthInfo'
import { RelatedTariff } from '@components/RelatedTariff/RelatedTariff'
import { authSelector } from '@store/auth/selectors'

export const ProfileTab = () => {
    const registrationDate = useSelector(profileSelector.registrationOfApplicationDate)
    const applicationDate = useSelector(profileSelector.applicationDate)
    const qualifyInfo = useSelector(profileSelector.selectQualifyInfo)
    const authData = useSelector(profileSelector.selectAuthData)
    const profileTypes = useSelector(profileSelector.selectProfileTypes)
    const showBlockAlert = authData && blockedRoles.includes(authData.role)
    const userUuid = useSelector(authSelector.selectUserUid)

    return (
        <>
            <Card>
                <Box margin={[16, 8, 0, 8]}>
                    {showBlockAlert && (
                        <>
                            <AlertUserBlock />
                            <Divider margin={[24, 0]} />
                        </>
                    )}
                    <Row>
                        <Col span={16}>
                            <QualificationInfo
                                qualifyInfo={qualifyInfo}
                                profileType={profileTypes?.[0]}
                            />
                        </Col>
                        <Col span={4}>
                            <CardVerticalField
                                title="Заявка на регистрацию"
                                value={
                                    registrationDate
                                        ? dayjs.unix(registrationDate).format(VIEW_DATE_FORMAT)
                                        : EMPTY_FIELD_VALUE
                                }
                            />
                        </Col>
                        <Col span={4}>
                            <CardVerticalField
                                title="Регистрация в системе"
                                value={
                                    applicationDate
                                        ? dayjs.unix(applicationDate).format(VIEW_DATE_FORMAT)
                                        : EMPTY_FIELD_VALUE
                                }
                            />
                        </Col>
                        <Col span={24}>
                            <Divider margin={[24, 0]} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <CertificatesList />
                        </Col>
                    </Row>
                </Box>
            </Card>
            <AuthInfo />
            <QuestionnaireInfo />
            <RelatedTariff userUuid={userUuid} />
        </>
    )
}
