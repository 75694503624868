import { blockedRoles, UserRoles } from '@dltru/dfa-models'
import { Alert, Box, VIEW_DATE_FORMAT } from '@dltru/dfa-ui'
import dayjs from 'dayjs'
import { FC } from 'react'
import { useSelector } from 'react-redux'

import { profileSelector } from '@store/profiles/details/selectors'

export const AlertUserBlock: FC = () => {
    const authData = useSelector(profileSelector.selectAuthData)

    if (!authData || !blockedRoles.includes(authData.role)) {
        return null
    }
    const message = `Учетная запись ${
        authData.role === UserRoles.CLIENT_PARTLY_BLOCK
            ? 'частично заблокирована'
            : ' полностью заблокирована'
    }`
    const description = (
        <Box padding={[0, 48, 0, 0]}>
            {authData.block_created_at && (
                <>
                    <span>
                        Дата блокирования{' '}
                        {dayjs.unix(authData.block_created_at).format(VIEW_DATE_FORMAT)}
                    </span>
                    <br />
                </>
            )}
            {Boolean(authData.block_reason) && <span>{authData.block_reason}</span>}
            {/* <UneditableUploadedFiles files={[{ uid: 'file' }]} /> */}
        </Box>
    )
    return <Alert message={message} description={description} type="error" showIcon />
}
