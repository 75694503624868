import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ChangeDataType } from '@dltru/dfa-models'
import { Box, Button, CardField, EMPTY_FIELD_VALUE, Space } from '@dltru/dfa-ui'

import { resetUserDataAction } from '@store/auth'
import { authSelector } from '@store/auth/selectors'

import styles from './style.m.less'

export const EmailBlock: FC = () => {
    const reduxDispatch = useDispatch()
    const authData = useSelector(authSelector.selectAuthData)

    const onChange = () => {
        reduxDispatch(resetUserDataAction(ChangeDataType.EMAIL))
    }

    const ValueComponent = (
        <Space direction="vertical" className={styles.mainWrapper}>
            <Space className={styles.rowWrapper} size={16}>
                <span className={styles.bold}>{authData?.email ?? EMPTY_FIELD_VALUE}</span>
                <Button type="link" onClick={onChange} disabled={!authData}>
                    Изменить
                </Button>
            </Space>
        </Space>
    )

    return (
        <Box margin={[0, -24, 0, -48]} padding={[0, 60, 0, 48]} className={styles.cardFieldWrapper}>
            <CardField title="Электронная почта" value={ValueComponent} padding={16} />
        </Box>
    )
}
