import { ITransferOrder, UnionOrderType } from '@dltru/dfa-models'
import { Box, Button, CardAdd, Table } from '@dltru/dfa-ui'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { authSelector } from '@store/auth/selectors'
import { dropTransferOrderList, loadTransferOrders } from '@store/orders/transfer'
import { transferOrderSelector } from '@store/orders/transfer/selectors'

import { gridConfig } from './gridConfig'

export const OtherDfaTab: FC = () => {
    const reduxDispatch = useDispatch()
    const userId = useSelector(authSelector.selectUserUid)
    const isLoading = useSelector(transferOrderSelector.selectIsLoading)
    const data = useSelector(transferOrderSelector.selectData)
    const navigate = useNavigate()

    useEffect(() => {
        if (userId) {
            reduxDispatch(loadTransferOrders({}))
        }
        return () => {
            reduxDispatch(dropTransferOrderList())
        }
    }, [userId])

    const onRow = (order: UnionOrderType) => ({
        onClick: () => {
            navigate(`/ownership-transfer-request/${order.global_id}`)
        },
    })

    return (
        <Box padding={[0, 24, 24, 24]} className="tablebox">
            <Table
                isLoading={isLoading}
                columns={gridConfig}
                dataSource={data as ITransferOrder[]}
                onRow={onRow}
                clickableRow
                showSorterTooltip={false}
            />

            <Box padding={16}>
                <Button
                    onClick={() => navigate('/ownership-transfer-request')}
                    borderRadius={12}
                    type="primary"
                    width="150px"
                    icon={<CardAdd />}
                >
                    Новая оферта
                </Button>
            </Box>
        </Box>
    )
}
