import { Space, Tag } from '@dltru/dfa-ui'
import { FC, useState } from 'react'

import { STATUS_LIST } from './resources'

export interface IComponentProps {
    onChange?: (selected: string[]) => void
}

export const FilterByStatus: FC<IComponentProps> = () => {
    const [selected, setSelected] = useState<string[]>(['Все'])
    const toggle = (value: string) => {
        setSelected((prevSelected) =>
            prevSelected.includes(value)
                ? prevSelected.filter((status) => status !== value)
                : [...prevSelected, value],
        )
    }

    return (
        <Space>
            {STATUS_LIST.map((value) => (
                <Tag.CheckableTag
                    key={value}
                    checked={selected.includes(value)}
                    onChange={() => toggle(value)}
                >
                    {value}
                </Tag.CheckableTag>
            ))}
        </Space>
    )
}
