import { CertificatesListComponent, ErrorCodes, errorTranslates, openMessage } from '@dltru/dfa-ui'
import React, { FC, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { authSelector } from '@store/auth/selectors'
import { certificateSlice } from '@store/certificates'
import { certificateSelectors } from '@store/certificates/selectors'

import { UploadCertificate } from './UploadCertificate/UploadCertificate'

export const CertificatesList: FC = () => {
    const profileUid = useSelector(authSelector.selectUserUid)
    const certificates = useSelector(certificateSelectors.selectItems)
    const isLoadingCertificate = useSelector(certificateSelectors.selectIsLoading)
    const reduxDispatch = useDispatch()
    useLayoutEffect(() => {
        if (certificates === null) {
            reduxDispatch(certificateSlice.actions.getItems())
        }
    }, [certificates])

    const addCertificateHandler = () => {
        reduxDispatch(certificateSlice.actions.getItems())
    }

    const addCertificateErrorHandler = (errorCode?: string) => {
        const description = errorCode ? errorTranslates[errorCode as ErrorCodes] : undefined
        openMessage({
            type: 'error',
            message: `Не удалось загрузить сертификат${description ? `: ${description}` : ''}`,
        })
    }

    const removeCertificateHandler = (uuid: string) => {
        reduxDispatch(certificateSlice.actions.removeItem(uuid))
    }

    return (
        <CertificatesListComponent
            isLoadingCertificate={isLoadingCertificate}
            certificates={certificates}
            addCertificateHandler={addCertificateHandler}
            addCertificateErrorHandler={addCertificateErrorHandler}
            removeCertificateAction={removeCertificateHandler}
            UploadCertificate={UploadCertificate}
            userUuid={profileUid}
        />
    )
}
