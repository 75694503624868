// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uploadCertificate_oaEEp {\n  margin-left: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ClientCard/MyProfile/components/ProfileTab/СertificatesBlock/UploadCertificate/UploadCertificate.m.less"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ","sourcesContent":[".uploadCertificate {\n    margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploadCertificate": "uploadCertificate_oaEEp"
};
export default ___CSS_LOADER_EXPORT___;
