import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ChangeDataType } from '@dltru/dfa-models'
import { Box, Button, CardField, Space } from '@dltru/dfa-ui'

import { resetUserDataAction } from '@store/auth'
import { profileSelector } from '@store/profiles/details/selectors'

import styles from './style.m.less'

export const PasswordBlock: FC = () => {
    const reduxDispatch = useDispatch()
    const authData = useSelector(profileSelector.selectAuthData)

    const onChange = () => {
        reduxDispatch(resetUserDataAction(ChangeDataType.PASSWORD))
    }

    const ValueComponent = (
        <Space direction="vertical" className={styles.mainWrapper}>
            <Space className={styles.rowWrapper} size={16}>
                <span className={styles.password}>
                    &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
                </span>
                <Button type="link" onClick={onChange} disabled={!authData}>
                    Изменить
                </Button>
            </Space>
        </Space>
    )

    return (
        <Box margin={[0, -24, 0, -48]} padding={[0, 60, 0, 48]} className={styles.cardFieldWrapper}>
            <CardField title="Пароль" value={ValueComponent} padding={16} />
        </Box>
    )
}
